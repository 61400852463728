<template>
    <div class="main-page" :dir="direction">
        <header>
            <h1>{{ $t(`message.pay_success`) }}</h1>

            <p class="message">{{ msg }}</p>

            <div id="dataTable" class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>{{ $t(`message.column_activation_code`) }}</th>
                            <th>{{ $t(`message.column_days`) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(days, code) in jsonData" :key="code">
                            <td>{{ code }}</td>
                            <td>{{ days }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </header>
        <footer>
            <div class="footer-links">
                <a href="https://sites.google.com/view/amanlinkprivatepolicy"><u>Privacy Policy</u></a>
                <a href="https://sites.google.com/view/aman-link-user-agreement"><u>User Agreement</u></a>
                <a href="https://t.me/amanlinkvpn"><u>Telegram</u></a>
                <a href="https://www.instagram.com/amanlinkvpn/"><u>Instagram</u></a>
                <a href="mailto:norman@mfree.net"><u>Email</u></a>
            </div>
            <p>&copy; AmanLink. All rights reserved.</p>
        </footer>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    computed: {
        direction() {
            // List of RTL language codes
            const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'az', 'ckb', 'ps', 'ug', 'dv'];
            // Check if the current locale is in the list of RTL languages
            return rtlLanguages.includes(this.$i18n.locale) ? 'rtl' : 'ltr';
        }
    },
    data() {
        return {
            msg: "",
            jsonData: [],
        };
    },
    created() {
        console.log("created")
        this.operation_id = this.getQueryParam('m_operation_id');
        this.stripe_session_id = this.getQueryParam('stripe_session_id');
    },
    mounted() {
        console.log("mounted")
        this.hideTable()
        this.fetchData();
    },
    methods: {
        getQueryParam(param) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
        },
        async fetchData() {
            try {
                const postData = JSON.stringify({ operationID: this.operation_id, stripe_session_id: this.stripe_session_id });
                const response = await axios.post('/api/query_activation_code', postData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                console.log('Data:', response.data);
                if (response.data.success) {
                    this.jsonData = response.data.data;
                    if ("all_used" in this.jsonData && this.jsonData["all_used"] === "1") {
                        this.hideTable()
                    } else {
                        this.showTable()
                    }
                } else {
                    this.msg = response.data.message
                    this.hideTable()
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        hideTable() {
            document.getElementById("dataTable").style.display = "none";
        },
        showTable() {
            document.getElementById("dataTable").style.display = "flex";
        }
    }
};
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
    /* background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); */
    color: black;
    font-family: Arial, sans-serif;
}

header {
    text-align: center;
    margin-top: 15%;
}

h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

p.message {
    font-size: 1.2em;
    color: #666;
}

.table-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

table {
    width: 80%;
    max-width: 600px;
    border-collapse: collapse;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

th,
td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

.subtitle {
    font-size: 1.2rem;
    margin: 10px 0 20px;
}


.app-store-button {
    display: flex;
    gap: 20px;
    /* Adjust the gap size as needed */
}

.app-store-button img {
    padding: 0;
    margin: 0;
    display: block;
    width: 150px;
    height: auto;
}

footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.footer-links {
    margin-bottom: 10px;
}

.footer-links a {
    color: black;
    text-decoration: none;
    margin: 0 10px;
}

.footer-links a:hover {
    text-decoration: underline;
}
</style>